<template>
    <div class="m-about">
		<div class="header-title">
			<div class="title1">会员专区</div>
			<div class="title2">Membership Service</div>
		</div>
        <div class="m-content  m-info-content">
            <!-- 会员风采 -->
			<div class="swiper-container swiperContainerTap1" id="swipertap1">
				<div class="swiper-wrapper swiperWrapperTap1">
					<div class="swiper-slide swiperSlideTap1" 
						v-for="item,index in taplist" :key = "index">
						<div class="type-list-SortName" v-if="item.typeSortName != ''">【{{item.typeSortName}}】</div>
						<div class="type-list" v-for="(list,i) in item.list" :key="i" 
						:class="list.typeId == memberTypeIndex?'active':''"
						@click="mobilememberType(list.typeId)">
						{{list.typeName}}
						</div>
					</div>
				</div>
			</div>
                <div v-infinite-scroll="loadMore" infinite-scroll-disabled="isScrollDisabled"
                     infinite-scroll-distance="18"
                     infinite-scroll-immediate-check="false">
                    <div class="members">
                        <div class="perMember" v-for="(item,index) in dataRes" @click="toDetail(item)" :key="index">
                            <img :src="item.fcImage" alt="">
                            <div class="memberInfo">
                                <p>{{item.fcName}}</p>
                                <p class="info-content">{{item.remark}}</p>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="message-bottom">{{nullText}}</div>
        </div>
    </div>
</template>

<script>
	import Swiper from 'swiper'
    export default {
        name: "mobile_memberList",
        mounted() {
            const typeId = this.$route.query.typeId
            if (typeId) {
                this.typeId = typeId
            }
            // this.getData()
			
        },
		created() {
			if (!/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/member",
						query:{
							typeId:this.$route.query.typeId
						}
			        });
			}
			this.getmobiletapList()
		},
        // computed: {
        //     isList: function () {
        //         return !(this.typeId == 'cwls' || this.typeId == 'ls' || this.typeId == 'hy')
        //     }
        // },
        watch: {
            $route(to) {
                if (to.path == '/mobile/member') {
                    const typeId = this.$route.query.memberType
                    if (typeId) {
                        this.typeId = typeId
                    }
                   
                }
            }
        },
        data() {
            return {
                typeId: '',
                tabIndex: 0,
                projectList: [],
                dataRes: null,
                current: 1,
                isMore: true,
                nullText: '',
                isEnd: false,
                isScrollDisabled: false,
				taplist:[],
				memberTypeIndex:''
            }
        },
        methods: {
            // 跳转详情
            toDetail(item) {
                this.$router.push({
                    path: '/mobile/member/detail',
                    query: {
                        id: item.fcId
                    }
                })
            },
			// 获取tab栏数据的时候，判断是否有id，有的话，执行projectBtn获取数据
            // getData() {
            //     let _this = this
            //     new Promise(function (resolve) {
            //         _this.$http.hyzqHyfcTypeList().then(res => {
            //             if (res.code == 200) {
            //                 _this.projectList = res.data
            //                 resolve();
            //             }
            //         })
            //     }).then(function () {
            //         // let index = 0;
            //         // if (_this.typeId) {
            //         //     index = _this.getTabIndex(_this.typeId);
            //         // }
            //         // _this.projectBtn(_this.projectList[index].typeId, index)
            //     })
            // },
            getInfoList(page, flag) {
                this.nullText = '加载中...';
                const _this = this
                _this.$http.hyzqHyfcList({
                    page: page,
                    typeId: _this.memberTypeIndex
                }).then(res => {
                    if (res.code == 200) {
                        if (flag) {//如果flag为true则表示分页
                            _this.dataRes = _this.dataRes.concat(res.data.records);  //concat数组串联进行合并
                            if (res.data.records.length == 0) {  //如果数据加载完 那么禁用滚动时间 this.isEnd设置为true
                                _this.isEnd = false;
                                this.isScrollDisabled = true
                                _this.nullText = '没有更多数据了';
                            } else {
                                _this.nullText = ''
                                this.isScrollDisabled = false
                            }
                        } else {//第一次进入页面 完全不需要数据拼接的
                            _this.dataRes = res.data.records;
                            if (res.data.records.length == 0) {
                                _this.isMore = false;
                                this.isScrollDisabled = true
                                _this.nullText = '暂无数据';
                            } else {
                                _this.nullText = ''
                                this.isScrollDisabled = false
                            }
                        }
                    }
                })
            },
            // getTabIndex(typeId) {
            //     for (let i = 0; i < this.projectList.length; i++) {
            //         const obj = this.projectList[i];
            //         if (obj.typeId == typeId) {
            //             return i;
            //         }
            //     }
            //     return 0
            // },
            // projectBtn(typeId) {
            //     this.typeId = typeId
              
            //     this.isEnd = false
            //         this.isScrollDisabled = false
            //         this.$http.hyzqHyfcList({
            //             page: 1,
            //             typeId: typeId
            //         }).then(res => {
            //             if (res.code == 200) {
            //                 this.dataRes = res.data.records
            //                 this.current = 1
            //             }
            //         })
              

            // },
            loadMore() {
				// console.log(this.dataRes)
                if (this.dataRes != null) {
                    this.isScrollDisabled = true
                    this.current++; //滚动之后加载第二页
                    this.getInfoList(this.current, true);
					// console.log(123)
                }
            },
			swiperInit() {
			    new Swiper('#swipertap1', {
			        // scrollbar: '.swiper-scrollbar',
			        freeMode: true,
			        scrollbarHide: true,
			        slidesPerView: 'auto',
			        centeredSlides: false,
			        // spaceBetween: 15	,
			        grabCursor: true,
			        slidesOffsetBefore: 20,
			        slidesOffsetAfter: 30,
			    });
			},
			// 获取列表信息
			getmobiletapList(){
				this.$http.hyzqTypeList().then(res => {
				    if (res.code == 200) {
						this.taplist = res.data
						// console.log(this.taplist)
						 this.memberTypeIndex = this.taplist[0].list[0].typeId
						 // console.log(this.taplist[0].list[0].typeId)
						 this.mobilememberType(this.memberTypeIndex)
						 setTimeout(()=>{
							  this.swiperInit();
						 },10)
				    }
				})
			},
			// 手机端tap栏切换
			mobilememberType(id){
				this.memberTypeIndex = id
				this.isScrollDisabled = false
				this.$http.hyzqHyfcList({
				    page: 1,
				    typeId: id
				}).then(res => {
				    if (res.code == 200) {
				        this.dataRes = res.data.records
				        this.current = 1
						this.nullText = this.dataRes == null || this.dataRes == "" || this.dataRes == undefined ? '暂无更多数据' : ''
				    }
				})
			}
        }
    }
</script>
<style type="text/css">

    .ulBox ul {
        width: 83.5vw;
        display: flex;
        flex-wrap: wrap;
        margin-top: 8.9vw;
    }

    .ulBox ul > li {
        width: 33%;
        color: #333333;
        margin-bottom: 6.7vw;
        text-align: center;
    }
</style>
<style scoped>
	.header-title{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 1.4rem 0.938rem 1.25rem 0.938rem;
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 2.25rem;
	}
	.header-title .title1{
		font-size: 1.125rem;
		color: #000000;
		margin-bottom: 0.6rem;
	}
	.header-title .title2{
		font-size: 0.75rem;
		color: #b3b6b5;
	}
	.m-content>div{
		min-height: inherit;
	}
    .m-open-info li {
        border-bottom: 1px solid #e5e5e5;
    }

    .m-open-info li:last-child {
        border-bottom: 0;
    }

    .m-open-info li a {
        display: block;
        overflow: hidden;
        padding: .8rem 0;
        color: #000;
    }

    .m-open-info li p {
        float: left;
        margin-bottom: 0;
        padding-left: 1.33333rem;
        position: relative;
        cursor: pointer;
        font-size: 1.13rem;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 0;
    }

    .m-open-info li p::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -.3rem;
        width: .6rem;
        height: .6rem;
        border-radius: 50%;
        background: #bd2221;
    }

    .m-open-info li span {
        float: right;
        color: #b3b6b5;
        font-size: 24px;
    }

    .m-open-info li:hover span,
    .m-open-info li:hover p {
        color: #c02c2b;
    }

    .m-open-info li:hover p {
        font-weight: bold;
    }

    .m-info-content {
        padding-top: 0;
    }

   /* .m-project-tab {
        display: flex;
        display: -webkit-flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 8vw;
    }

    .m-project-tab li {
        width: 30%;
        height: 2.66666666667rem;
        background: #f2f2f2;
        font-size: 1rem;
        color: #000;
        text-align: center;
        margin-right: 1rem;
        line-height: 2.66666666667rem;
        margin-bottom: 1.26666666667rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 .3rem;
    }

    .m-manager-tab li {
        width: 40%;
    }

    .m-project-tab li:nth-child(3n) {
        margin-right: 0;
    }

    .m-project-tab li.active {
        background: #005bac;
        color: #fff;
    } */
	/* 顶部tap栏切换 */
		.swiper-container.swiperContainerTap1 {
		    width: 100%;
		    margin: 0 auto;
		}
	 .swiper-wrapper.swiperWrapperTap1{
			display: flex;
		}
		.swiper-wrapper.swiperWrapperTap1 .swiper-slide.swiperSlideTap1{
			display: flex;
			flex-shrink: 0;
			margin-left: 2vw;
			width: initial;
			position: relative;
		}
		.swiper-wrapper.swiperWrapperTap1 .swiper-slide.swiperSlideTap1::after{
			content: "";
			position: absolute;
			right: 0;
			height: 5vw;
			width: 1px;
			background: #000000;
		}
		.swiper-wrapper.swiperWrapperTap1 .swiper-slide.swiperSlideTap1 .type-list-SortName{
			font-size: 3.2vw;
			color: #333333;
			flex-shrink: 0;
			font-weight: bold;
		}
		.swiper-wrapper.swiperWrapperTap1 .swiper-slide.swiperSlideTap1 .type-list{
			display: flex;
			flex-shrink: 0;
			font-size: 3.2vw;
			height: 25px;
			color: #333333;
			margin-right: 3vw ;
			position: relative;
			width: initial;
		}
	.swiper-wrapper.swiperWrapperTap1 .swiper-slide.swiperSlideTap1 .type-list.active{
		
		  /* background-color: #005bac; */
		  position: relative;
	}
	.swiper-wrapper.swiperWrapperTap1 .swiper-slide.swiperSlideTap1 .type-list.active::after{
		   content: "";
		   position: absolute;
		   left: 0;
		   bottom: 0;
		   height: 0.125rem;
		   width: 100%;
		   background: #005bac;
	}

    .perMember {
        width: 43.5vw;
        height: 81.7vw;
        font-size: 4vw;
        color: #333333;
        position: relative;
        margin-bottom: 5vw;
    }

    .perMember img {
        width: 43.2vw;
        height: 58.5vw;
    }

    .info-content {
        font-size: 3vw;
        color: #999999;
        height: 10vw;
        line-height: 5vw;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .memberInfo {
        padding: 3vw 5vw 6vw 3vw;
        background-color: #f8f8f8;
        position: absolute;
        width: 43.5vw;
        height: 23vw;
        top: 58.5vw;
    }

    .memberInfo > p:nth-of-type(2) {
        margin-top: 2.7vw;
    }

    .members {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 8vw;
    }

    .m-news-info {
        overflow: hidden;
        margin-top: 8vw;
    }

    .m-news-info li {
        margin-bottom: 2rem;
        overflow: hidden;
        cursor: pointer;
    }

    .m-news-info li:last-child {
        border-bottom: none;
    }

    .m-left-img {
        float: left;
        margin-right: 1rem;
        width: 7.458rem;
        height: 5.082rem;
        border-radius: .264rem;
        overflow: hidden;
    }

    .m-left-img:hover img {
        transform: scale(1.2);
    }

    .m-left-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
    }

    .m-right-content {
    }

    .m-right-title {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .67rem;
    }

    .m-right-title h3 {
        font-weight: normal;
        overflow: hidden;
        width: 100%;
        height: 3.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #17191a;
        font-size: 1.122rem;
        line-height: 1.55rem;
    }

    .m-right-content p {
        margin-bottom: 0;
        line-height: 1.3;
        color: #879399;
        font-size: .792rem;
    }

    .corporates {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 6vw;
    }

    .perCorporate {
        width: 43vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 8vw;
    }

    .corporateImg {
        width: 43vw;
        height: 20vw;
        background-color: #ffffff;
        border: solid 1px #dbdcdc;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .corporateImg img {
        width: 38vw;
        height: 15vw;
    }

    .perCorporate p {
        width: 38vw;
        text-align: center;
        margin-top: 3vw;
        font-size: 4vw;
        color: #333333;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .menberHonor {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 8vw;
    }

    .perHonor {
        width: 43vw;
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 5vw;
    }

    .perHonor img {
        width: 43vw;
        height: 27vw;
    }

    .perHonor p {
        font-size: 4vw;
        width: 36vw;
        display: -webkit-box;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin: 4vw 0vw 9vw 0vw;
    }
	.message-bottom{
		display: flex;
		justify-content: center;
		font-size: 3vw;
		color: #CCCCCC;
	}
</style>
